<template>
  <div class="p20">
    <table class="ui celled table"> 
      <thead>
        <tr>
          <th>id</th>
          <th>Category</th>
          <th>Number of Questions</th>
          <th>1 Mark</th>
          <th>2 Mark</th>
          <th>3 Mark</th>
          <th>4 Mark</th>
          <th>5 Mark</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(q, category) in questionsByCategory.counts" :key="category">
          <td>{{category}}</td>
          <td>{{getCategoryName(category)}}</td>
          <td>{{q}}</td>
          <td>{{questionsByCategory['marks'][category][1]}}</td>
          <td>{{questionsByCategory['marks'][category][2]}}</td>
          <td>{{questionsByCategory['marks'][category][3]}}</td>
          <td>{{questionsByCategory['marks'][category][4]}}</td>
          <td>{{questionsByCategory['marks'][category][5]}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'quizCategories',
  data () {
    return {
      questions: []
    }
  },
  mounted () {
    this.$req.get('questions?_limit=-1', {
      headers: {
        Authorization: `Bearer ${this.$bus.token}`
      }
    })
      .then(r => {
        this.questions = r.data
      }).catch(e => console.log(e))
  },
  computed: {
    questionsByCategory: function () {
      let counts = {}
      let marks = {}
      this.questions.forEach(q => {
        if (q.Category) {
          counts[q.Category.id] = (counts[q.Category.id] || 0)
          counts[q.Category.id] += 1
          marks[q.Category.id] = (marks[q.Category.id] || {})
          marks[q.Category.id][q.Marks] = (marks[q.Category.id][q.Marks] || 0)
          marks[q.Category.id][q.Marks] += 1
        }
      })
      return {counts, marks}
    }, 
    categories: function () {
      return this.questions.map(q => q.Category)
    }
  },
  methods: {
    getCategoryName: function (id) {
      return this.categories.find(c => c?.id == id)?.name
    }
  }
}
</script>